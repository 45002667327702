import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, Grid, Divider, useMediaQuery } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { useTheme, withStyles, makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  bottomNavGrid: {
    '& a': {
      marginBottom: '9px',
      lineHeight: '16px',
    },
  },
  bottomNavDivider: {
    backgroundColor: theme.palette.background.paper,
    height: '1px',
    margin: '14px 0',
    width: '80%',
  },
}))
const Accordion = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.dark,
    boxShadow: 'none',
    border: 'none',
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: { color: theme.palette.common.white },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {},
}))(MuiAccordionDetails)

const BottomNavMenu = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'))
  const hasMenu1 = !!props.blok.menu1 && !!props.blok.menu1.length
  const hasMenu2 = !!props.blok.menu2 && !!props.blok.menu2.length

  return (
    <SbEditable content={props.blok}>
      <>
        {mdDown ? (
          <Accordion square>
            <AccordionSummary
              expandIcon={<Icon htmlColor="#FFFFFF">ExpandMore</Icon>}
              aria-label="Expand"
              aria-controls={`controls${props.blok._uid}`}
              id={`id-${props.blok._uid}`}
            >
              {
                <Box
                  aria-label="HeadingLink"
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                >
                  {renderBloks(props.blok.heading)}
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Grid container direction={hasMenu2 ? 'row' : 'column'}>
                  <Grid
                    container
                    xs={hasMenu2 ? 6 : 12}
                    item
                    direction="column"
                  >
                    {hasMenu1 &&
                      props.blok.menu1.map((blok) => (
                        <Grid key={blok._uid} container item>
                          <Box my="10px">{renderBlok(blok)}</Box>
                        </Grid>
                      ))}
                  </Grid>
                  {hasMenu2 && (
                    <Grid container xs={6} item direction="column">
                      {props.blok.menu2.map((blok) => (
                        <Grid key={blok._uid} container item>
                          <Box my="10px">{renderBlok(blok)}</Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box>
            <Box>{renderBloks(props.blok.heading)}</Box>
            <Box>
              <Box
                mt={4}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Grid
                  className={classes.bottomNavGrid}
                  container
                  item
                  direction={hasMenu2 ? 'row' : 'column'}
                >
                  <Grid container item direction={hasMenu2 ? 'row' : 'column'}>
                    {hasMenu1 &&
                      props.blok.menu1.map((blok) => (
                        <Grid
                          key={blok._uid}
                          container
                          item
                          xs={hasMenu2 ? 5 : null}
                        >
                          {renderBlok(blok)}
                        </Grid>
                      ))}
                  </Grid>
                  {hasMenu2 && (
                    <Divider className={classes.bottomNavDivider} light />
                  )}
                  {hasMenu2 && (
                    <Grid container direction="row">
                      {props.blok.menu2.map((blok) => (
                        <Grid key={blok._uid} container item xs={5}>
                          {renderBlok(blok)}
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </SbEditable>
  )
}

export default BottomNavMenu
